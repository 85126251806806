.footer {
    border-top: 1px solid lightgray;
    padding: 2px;
    background-color: #f7f7f7;
    text-align: center;
   position: bottom; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
bottom :0 
    
}

.footer > p {
    padding: 5px;
    font-size: 14px;
}
